/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listFirmRole = params => axios({
    url: '/api/firm/firm/firmRole/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addFirmRole = params => axios({
    url: '/api/firm/firm/firmRole/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editFirmRole = params => axios({
    url: '/api/firm/firm/firmRole/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delFirmRole = params => axios({
    url:'/api/firm/firm/firmRole/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdFirmRole = params => axios({
    url: '/api/firm/firm/firmRole/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
